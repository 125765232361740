module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NETengine","short_name":"NETengine","start_url":"https://www.netengine.at","background_color":"white","theme_color":"#376591","display":"standalone","icon":"src/assets/svg/logos/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1230d9541d1f110e18e136c41d19eefe"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
