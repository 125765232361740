// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-agbs-js": () => import("./../../../src/pages/agbs.js" /* webpackChunkName: "component---src-pages-agbs-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-hilfe-js": () => import("./../../../src/pages/hilfe.js" /* webpackChunkName: "component---src-pages-hilfe-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-js": () => import("./../../../src/pages/jobs.js" /* webpackChunkName: "component---src-pages-jobs-js" */),
  "component---src-pages-jobs-junior-web-entwickler-js": () => import("./../../../src/pages/jobs/junior-web-entwickler.js" /* webpackChunkName: "component---src-pages-jobs-junior-web-entwickler-js" */),
  "component---src-pages-jobs-php-web-entwickler-js": () => import("./../../../src/pages/jobs/php-web-entwickler.js" /* webpackChunkName: "component---src-pages-jobs-php-web-entwickler-js" */),
  "component---src-pages-jobs-typo-3-entwickler-js": () => import("./../../../src/pages/jobs/typo3-entwickler.js" /* webpackChunkName: "component---src-pages-jobs-typo-3-entwickler-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

